.ArrowButton_circle__lWKOZ {
  height: 36px;
  width: 36px;

  cursor: pointer;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ArrowButton_circle__lWKOZ:hover {
  background-color: #09af77;
}

.Button_button__AlQaw {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;

  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition-duration: 300ms;
  white-space: nowrap;
}
.Button_horizontal____QTZ:hover {
  padding: 16px 18px;
}

.Button_both__BXik9:hover {
  transform: scale(0.9);
}

.Button_light__JsKN5.Button_both__BXik9:hover {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #01432d61 inset;
  color: #262628;
}

.Button_horizontal____QTZ:active {
  padding: 16px 18px;
}
.Button_both__BXik9:active {
  transform: scale(0.9);
}

.Button_primary__fea80 {
  box-shadow: 0px 7px 10.3px 0px rgba(9, 175, 119, 0.48);
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
}
.Button_primary__fea80:hover {
  box-shadow: unset;
}

.Button_primary__fea80:active {
  background-color: #ffffff;
  color: #262628;
}

.Button_primaryKsa__iOtNG {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_primaryKsa__iOtNG:hover {
  box-shadow: unset;
}

.Button_primaryKsa__iOtNG:active {
  box-shadow: 0px 4px 4px 0px #01553961 inset;
  background-color: #ffffff;
  color: #262628;
}

.Button_redButton__bxim7 {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #b02a09;
  border-radius: 10px;
  background-color: #b02a09;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}

.Button_redButton__bxim7:hover {
  box-shadow: unset;
}

.Button_redButton__bxim7:active {
  background-color: #f5eae7;
  border: 1px solid #b02a09;
  box-shadow: 0px 4px 4px 0px #4a0b0b4d inset;
  color: #262628;
}

.Button_redLineButton__hZ_YH {
  box-shadow: 0px 4px 7.5px 0px #40100452;
  border: 1px solid #b02a09;
  border-radius: 10px;
  background-color: transparent;
}

.Button_redLineButton__hZ_YH:hover {
  box-shadow: unset;
}

.Button_redLineButton__hZ_YH:active {
  box-shadow: 0px 4px 4px 0px #4a0b0b4a inset;
}

.Button_blueButton__wvXK_ {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #0052b4;
  border-radius: 10px;
  background-color: #0052b4;
}

.Button_blueButton__wvXK_:hover {
  box-shadow: unset;
}

.Button_blueButton__wvXK_:active {
  background-color: #fafffd;
  border: 1px solid #0052b4;
  box-shadow: 0px 4px 4px 0px #0052b44a inset;
  color: #262628;
}

.Button_black__MR639 {
  box-shadow: 0px 4px 7.5px 0px rgba(38, 38, 40, 0.5);
  border: 1px solid #262628;
  border-radius: 10px;
  background-color: #262628;
}
.Button_black__MR639:hover {
  background-color: #ffffff;
  color: #262628;
}
.Button_black__MR639:active {
  background-color: #ffffff;
}

.Button_secondary__tl__f {
  box-shadow: 0px 7px 10.3px 0px rgba(38, 38, 40, 0.5);
  border: 1px solid #eae4ec;
  border-radius: 10px;
  background-color: #eae4ec;
  color: #262628;
}

.Button_secondary__tl__f:hover {
  background-color: #ffffff;
  border: 1px solid #d9a8e5;
  box-shadow: none;
}

.Button_secondary__tl__f:active {
  background-color: #09af77;
  border: 1px solid #eae4ec;
  box-shadow: 0px 4px 4px 0px rgba(38, 38, 40, 0.5) inset;
}

.Button_vertical__eY3pi {
  top: 310px;
  right: -38px;
  color: #262628;
  border-radius: 10px 10px 0 0;
  border: 1px solid #09af77;
  transform: scale(1) rotate(-90deg);
}
.Button_vertical__eY3pi:hover {
  transform: scale(0.9) rotate(-90deg);
}

.Button_primaryVideo__iDq1p {
  box-shadow: 0px 4px 7.5px 0px #09af7780;
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_primaryVideo__iDq1p:hover {
  box-shadow: unset;
}

.Button_primaryVideo__iDq1p:active {
  box-shadow: 0px 4px 4px 0px #01553961 inset;
  background-color: #ffffff;
  color: #262628;
}

.Button_blackVideo__5Kxtb {
  box-shadow: 0px 4px 7.5px 0px #26262880;

  border: 1px solid #262628;
  border-radius: 10px;
  background-color: #262628;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_blackVideo__5Kxtb:hover {
  box-shadow: unset;
}
.Button_blackVideo__5Kxtb:active {
  color: #262628;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #26262861 inset;
}

.Button_blueLine__ZHzzr {
  box-shadow: 0px 4px 7.5px 0px #0052b480;

  border: 1px solid #0052b4;
  border-radius: 10px;

  color: #0052b4;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_blueLine__ZHzzr:hover {
  box-shadow: unset;
}
.Button_blueLine__ZHzzr:active {
  background-color: #f6faff;
  box-shadow: 0px 4px 4px 0px #0052b44a inset;
}

.Button_noLine__Mv2fc {
  border-width: 1px;

  border-radius: 10px;
  box-sizing: border-box;
  color: #09af77;
}
.Button_noLine__Mv2fc:hover {
  border: 1px solid #09af77;
}
.Button_noLine__Mv2fc:active {
  border: 1px solid #09af77;
  color: #262628;
}

.Checkbox_checkmark__MCE_d:after {
  position: absolute;
  transform: rotate(45deg);
  content: '';
  display: none;
  left: 7px;
  top: 1px;
  width: 8px;
  height: 14px;
  border: solid #09af77;
  border-width: 0 2px 2px 0;
}

.Radio_radio-item__C3npE input[type='radio'] {
  display: none;
}

.Radio_radio-item__C3npE label {
  color: #262628;
  font-size: 16px;
  cursor: pointer;
}

.Radio_radio-item__C3npE label:before {
  content: ' ';
  margin: 0 5px 0 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #09af77;
  background-color: transparent;
  vertical-align: middle;
}

.Radio_radio-item__C3npE input[type='radio']:checked + label:before {
  content: ' ';
  border: 7px solid #09af77;
}

.Segment_green_segment_active__PWkdm {
  --tw-shadow: 0px 4px 4px 0px #4DA454 inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Segment_purple_segment_active__5fPNh {
  --tw-shadow: 0px 4px 4px 0px #A27BAB inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Segment_green_segment__ezvly:hover {
  border-color: #4da454;
}

.Segment_purple_segment__C1fZi:hover {
  border-color: #d9a8e5;
}

.Segment_green_segment__ezvly, .Segment_purple_segment__C1fZi {
  display: flex;
  min-height: 65px;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {

  .Segment_green_segment__ezvly, .Segment_purple_segment__C1fZi {
    max-width: 250px;
  }
}

.SegmentSingle_green_segment_active__TQJwe {
  --tw-shadow: 0px 4px 4px 0px #4DA454 inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingle_purple_segment_active__eBmQJ {
  --tw-shadow: 0px 4px 4px 0px #A27BAB inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingle_green_segment__zFA59:hover {
  border-color: #4da454;
}

.SegmentSingle_purple_segment__qG5hi:hover {
  border-color: #d9a8e5;
}

.SegmentSingle_green_segment__zFA59, .SegmentSingle_purple_segment__qG5hi {
  display: flex;
  min-height: 68px;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {

  .SegmentSingle_green_segment__zFA59, .SegmentSingle_purple_segment__qG5hi {
    max-width: 250px;
  }
}

.SegmentSingleMob_green_segment_active__2j8A_ {
  --tw-shadow: 0px 4px 4px 0px #4DA454 inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingleMob_purple_segment_active__f9pF4 {
  --tw-shadow: 0px 4px 4px 0px #A27BAB inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingleMob_green_segment__m3eaq:hover {
  border-color: #4da454;
}

.SegmentSingleMob_purple_segment__eOcEc:hover {
  border-color: #d9a8e5;
}

.SegmentSingleMob_green_segment__m3eaq, .SegmentSingleMob_purple_segment__eOcEc {
  display: flex;
  min-height: 68px;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {

  .SegmentSingleMob_green_segment__m3eaq, .SegmentSingleMob_purple_segment__eOcEc {
    max-width: 250px;
  }
}

.Hero_social_blok__sBTto {
  background: #ffffff4d;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #ffffff;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.OurServices_custom-list__qBUid {
  padding-left: 10px;
}

.OurServices_custom-list__qBUid li {
  position: relative;
  padding-left: 16px;
  line-height: 22px;
}

.OurServices_custom-list__qBUid li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #333;
  font-size: 22px;
  line-height: 1;
}

.OurServices_tab_button__6ITRn {
  border-radius: 10px;
  border-width: 1px;
  border-color: inherit;
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  --tw-text-opacity: 1;
  color: rgb(38 38 40 / var(--tw-text-opacity));
}

.OurServices_tab_button__6ITRn.OurServices_hover_tab__6dula:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(199 182 243 / var(--tw-bg-opacity));
}

.OurServices_tab_button__6ITRn.OurServices_active_tab__UULh6 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 182 243 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));

  pointer-events: none;
}

.OurServices_button_animation__jX2hx {
  color: #000;
  background: linear-gradient(90deg, #eeeaf9 50%, #c7b6f3 50%);
  background-size: 200% 100%;
  transition: 0.5s;
}

.OurServices_button_animation__jX2hx:hover {
  background-position: -100% 0;
}

.OurServices_hide_scrollbar__XzDnY::-webkit-scrollbar {
  display: none;
}
.OurServices_hide_scrollbar__XzDnY {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.OurServices_background_shine__t063E:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0px;
  animation: OurServices_slide__MNMO9 4s infinite; /* FF3.6+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes OurServices_slide__MNMO9 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* style.module.css */
.style_skeleton__CUZrx {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 200px 100%;
  background-position: left -100px top 0;
  animation: style_shine__gu4G7 2s linear infinite;
  position: relative;
  z-index: 100;
}

@keyframes style_shine__gu4G7 {
  50% {
    background-position: right -100px top 0;
  }

  100% {
    background-position: left -100px top 0;
  }
}

.InsideProdigi_tab_button__SC7w_ {

  border-radius: 10px;

  border-width: 1px;

  border-color: inherit;

  padding: 16px;

  font-size: 18px;

  font-weight: 500;

  line-height: 22px;

  --tw-text-opacity: 1;

  color: rgb(38 38 40 / var(--tw-text-opacity));

  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;

  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  transition-duration: 150ms;
}

.InsideProdigi_tab_button__SC7w_.InsideProdigi_hover_tab__sZGCA:hover {

  --tw-bg-opacity: 1;

  background-color: rgb(199 182 243 / var(--tw-bg-opacity));
}

.InsideProdigi_tab_button__SC7w_.InsideProdigi_active_tab__dldd6 {

  --tw-bg-opacity: 1;

  background-color: rgb(199 182 243 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));

  pointer-events: none;
}

.InsideProdigi_card__N_k4F {
  box-shadow: 2px 4px 10px 0px #00000033;
  background: #f3f2f8;
  border-radius: 10px;
  padding: 23px 65px;
  height: 384px;
  width: 100%;
  animation: InsideProdigi_fade-in__pOK3P 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes InsideProdigi_fade-in__pOK3P {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.InsideProdigiMobile_tab_button__wHxXL {

  border-radius: 10px;

  border-width: 1px;

  border-color: inherit;

  padding: 16px;

  font-size: 18px;

  font-weight: 500;

  line-height: 22px;

  --tw-text-opacity: 1;

  color: rgb(38 38 40 / var(--tw-text-opacity));
}

.InsideProdigiMobile_tab_button__wHxXL.InsideProdigiMobile_hover_tab__YWXmv:hover {

  --tw-bg-opacity: 1;

  background-color: rgb(199 182 243 / var(--tw-bg-opacity));
}

.InsideProdigiMobile_tab_button__wHxXL.InsideProdigiMobile_active_tab__Og1sB {

  --tw-bg-opacity: 1;

  background-color: rgb(199 182 243 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));

  pointer-events: none;
}

.InsideProdigiMobile_card__7PiMo {
  box-shadow: 2px 4px 10px 0px #00000033;
  background: #f3f2f8;
  border-radius: 10px;
  height: 775px;
  width: 330px;
}

.Navigation_home_navigation__RnNCn {

  display: flex;

  cursor: pointer;

  flex-direction: row;

  justify-content: center;

  gap: 24px;

  border-radius: 55px;

  padding-top: 16px;

  padding-bottom: 16px;

  padding-left: 24px;

  padding-right: 24px;

  background: #e9f3ffe5;
  box-shadow: 0px 3px 7px 0px #00000026;
  max-width: -moz-max-content;
  max-width: max-content;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px)
}

.Navigation_tab_button__A_6j_ {

  font-size: 18px;

  font-weight: 500;

  line-height: 22px;

  --tw-text-opacity: 1;

  color: rgb(38 38 40 / var(--tw-text-opacity))
}

.Navigation_tab_button__A_6j_.Navigation_hover_tab__Hy5tv:hover {

  --tw-text-opacity: 1;

  color: rgb(0 82 180 / var(--tw-text-opacity))
}

.Navigation_tab_button__A_6j_.Navigation_active_tab__WPXSv {

  font-weight: 600;

  --tw-text-opacity: 1;

  color: rgb(0 82 180 / var(--tw-text-opacity));

  pointer-events: none
}

.TrustedBy_testimonials__TBMza {
  transform: rotate(-90deg) translate(0px, -290px);
  font-weight: 700;
  font-size: 53px;
  line-height: 65px;
  letter-spacing: 0.12em;
  color: rgba(38, 38, 40, 0.08);
  top: 205px;
}

.TrustedBy_testimonial_item__CdyX9 {
  background: #f3f2f8;
  border: 1px solid #ffffff;
  box-shadow:
    7px 7px 10.3px 0px #d0ccda,
    -10px -5px 7px 0px #ffffff8f,
    2px -8px 7px 0px #ffffff8f;
}

.TrustedBy_video_item__22VbW {
  box-shadow:
    7px 7px 10.3px 0px #d0ccda,
    -10px -5px 7px 0px #ffffff8f,
    2px -8px 7px 0px #ffffff8f;
  border-radius: 5px;
  overflow: hidden;
}

.MeetTeam_teem_card__Zh7ir {
  background: #f5f5f5;
  box-shadow: 2px 4px 10px 0px #00000033;
  border-radius: 10px;
  padding: 100px 95px 48px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .MeetTeam_teem_card__Zh7ir {
    padding: 62px 10px 24px 10px;
  }
}

.MeetTeam_pulse__kD_t_ {
  animation: MeetTeam_pulse-animation__ezAqf 2s infinite;
}

@keyframes MeetTeam_pulse-animation__ezAqf {
  0% {
    box-shadow: 0 0 0 0px rgb(9 175 119 0.7);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(176, 42, 9, 0);
  }
}

