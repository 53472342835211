.tab_button {
  @apply border border-inherit p-[16px] text-[18px] font-medium leading-[22px] rounded-[10px] text-black transition-colors;
}

.tab_button.hover_tab:hover {
  @apply bg-[#C7B6F3];
}

.tab_button.active_tab {
  @apply bg-[#C7B6F3] text-white;

  pointer-events: none;
}

.card {
  box-shadow: 2px 4px 10px 0px #00000033;
  background: #f3f2f8;
  border-radius: 10px;
  padding: 23px 65px;
  height: 384px;
  width: 100%;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
