.tab_button {
  @apply border border-inherit p-[16px] text-[18px] font-medium leading-[22px] rounded-[10px] text-black;
}

.tab_button.hover_tab:hover {
  @apply bg-[#C7B6F3];
}

.tab_button.active_tab {
  @apply bg-[#C7B6F3] text-white;

  pointer-events: none;
}

.card {
  box-shadow: 2px 4px 10px 0px #00000033;
  background: #f3f2f8;
  border-radius: 10px;
  height: 775px;
  width: 330px;
}
