.custom-list {
  padding-left: 10px;
}

.custom-list li {
  position: relative;
  padding-left: 16px;
  line-height: 22px;
}

.custom-list li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #333;
  font-size: 22px;
  line-height: 1;
}

.tab_button {
  @apply border border-inherit p-[16px] text-[18px] font-medium leading-[22px] rounded-[10px] text-black;
}

.tab_button.hover_tab:hover {
  @apply bg-[#C7B6F3];
}

.tab_button.active_tab {
  @apply bg-[#C7B6F3] text-white;

  pointer-events: none;
}

.button_animation {
  color: #000;
  background: linear-gradient(90deg, #eeeaf9 50%, #c7b6f3 50%);
  background-size: 200% 100%;
  transition: 0.5s;
}

.button_animation:hover {
  background-position: -100% 0;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}
.hide_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.background_shine:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0px;
  animation: slide 4s infinite;

  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6+ */

  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
