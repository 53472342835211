.home_navigation {
  @apply flex flex-row justify-center gap-[24px] py-[16px] px-[24px] rounded-[55px] cursor-pointer;

  background: #e9f3ffe5;
  box-shadow: 0px 3px 7px 0px #00000026;
  max-width: max-content;
  backdrop-filter: blur(4px);
}

.tab_button {
  @apply text-[18px] font-medium leading-[22px] text-black;
}

.tab_button.hover_tab:hover {
  @apply text-[#0052B4];
}

.tab_button.active_tab {
  @apply text-[#0052B4] font-semibold;

  pointer-events: none;
}
