.testimonials {
  transform: rotate(-90deg) translate(0px, -290px);
  font-weight: 700;
  font-size: 53px;
  line-height: 65px;
  letter-spacing: 0.12em;
  color: rgba(38, 38, 40, 0.08);
  top: 205px;
}

.testimonial_item {
  background: #f3f2f8;
  border: 1px solid #ffffff;
  box-shadow:
    7px 7px 10.3px 0px #d0ccda,
    -10px -5px 7px 0px #ffffff8f,
    2px -8px 7px 0px #ffffff8f;
}

.video_item {
  box-shadow:
    7px 7px 10.3px 0px #d0ccda,
    -10px -5px 7px 0px #ffffff8f,
    2px -8px 7px 0px #ffffff8f;
  border-radius: 5px;
  overflow: hidden;
}
