.teem_card {
  background: #f5f5f5;
  box-shadow: 2px 4px 10px 0px #00000033;
  border-radius: 10px;
  padding: 100px 95px 48px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .teem_card {
    padding: 62px 10px 24px 10px;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(9 175 119 0.7);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(176, 42, 9, 0);
  }
}
