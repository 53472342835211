/* style.module.css */
.skeleton {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 200px 100%;
  background-position: left -100px top 0;
  animation: shine 2s linear infinite;
  position: relative;
  z-index: 100;
}

@keyframes shine {
  50% {
    background-position: right -100px top 0;
  }

  100% {
    background-position: left -100px top 0;
  }
}
